import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Headline } from '../Headline/Headline';

type StyledHeaderProps = {
    showMeta: boolean;
};

export const StyledHeader = styled.div<StyledHeaderProps>(({ theme, showMeta }) => ({
    display: 'flex',
    alignItems: 'end',
    marginBottom: theme.spaces['3'],
    gap: theme.gridConfig.gutter,
    justifyItems: 'start',

    [breakpoints.sm]: {
        alignItems: 'center',
        gridTemplateAreas: showMeta ? `'headline link meta'` : `'headline link'`,
    },
}));

export const StyledArea = styled.div<{ area: 'headline' | 'link' | 'meta' }>(
    switchProp('area', {
        meta: {
            display: 'flex',
            justifyContent: 'end',
        },
        link: {
            whiteSpace: 'nowrap',
        },
        headline: {
            width: '100%',
        },
    })
);

export const StyledModuleHeadline = styled(Headline)(({ theme }) => ({
    maxWidth: theme.maxWidthInCharacters['3xl'],
}));
