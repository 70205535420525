import { RawHtml } from '$shared/components/RawHtml';
import React, { useMemo } from 'react';
import { M100RichTextModule } from '~/lib/data-contract';
import { ModuleHeader } from '~/templates/blocks/components/ModuleHeader';
import { ModuleContainer } from '../ModuleContainer';
import { StyledColumns, StyledM100RichText, StyledTextContainer } from './styled';

export type M100RichTextProps = M100RichTextModule;

export const M100RichText = ({
    text,
    headline,
    secondaryColumn,
    centered,
    ...rest
}: M100RichTextProps) => {
    const columnCount = useMemo(() => [text, secondaryColumn].filter(Boolean).length, [
        text,
        secondaryColumn,
    ]);

    return (
        <ModuleContainer hasGutter {...rest}>
            <StyledTextContainer columnCount={columnCount} centered={centered}>
                <StyledM100RichText columnCount={columnCount}>
                    <ModuleHeader headline={headline} variant="display2" {...rest} />
                    {(text || secondaryColumn) && (
                        <StyledColumns>
                            {text && <RawHtml html={text} {...rest} />}
                            {secondaryColumn && <RawHtml html={secondaryColumn} {...rest} />}
                        </StyledColumns>
                    )}
                </StyledM100RichText>
            </StyledTextContainer>
        </ModuleContainer>
    );
};
