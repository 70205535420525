import { Button, RouterLink, TextVariants } from '$shared/components';
import React from 'react';
import { Link as LinkType } from '~/lib/data-contract';
import { PotentialThemeColorType } from '~/theme';
import { useThemeShade } from '~/theme';
import { StyledArea, StyledHeader, StyledModuleHeadline } from './styled';

type ModuleHeaderProps = {
    headline?: string;
    variant?: TextVariants;
    link?: LinkType;
    backgroundColor?: PotentialThemeColorType;

    // Adds children into an optional meta area to the right
    children?: React.ReactNode;
};

export const ModuleHeader = ({
    headline,
    variant = 'display1',
    link,
    children,
    backgroundColor,
}: ModuleHeaderProps) => {
    const { headlineShade, buttonShade } = useThemeShade({ backgroundColor });

    return (
        <>
            <StyledHeader showMeta={!!children}>
                {headline ? (
                    <StyledArea area="headline">
                        <StyledModuleHeadline
                            variant={variant}
                            children={headline}
                            shade={headlineShade}
                        />
                    </StyledArea>
                ) : null}
                {link?.url ? (
                    <StyledArea area="link">
                        <RouterLink href={link.url}>
                            <Button
                                as="a"
                                target={link.target}
                                size="lg"
                                variant="tertiary"
                                shade={buttonShade}
                            >
                                {link.text}
                            </Button>
                        </RouterLink>
                    </StyledArea>
                ) : null}
                {children ? <StyledArea area="meta" children={children} /> : null}
            </StyledHeader>
        </>
    );
};
